import { TableHead, Tooltip, TableSortLabel, LinearProgress, TextField, Paper, Button, Table, TableBody, TableRow, TableCell, Checkbox, IconButton, TablePagination } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";


export const GridClientSide = ({ defaultSortBy, defaultSortOrder }) => {
    return (
        <div>Grid</div>
    );
};

export const SortOrder = {
    asc: 'asc',
    desc: 'desc'
};

//     const [searchTerm, setSearchTerm] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(0);
//     const [perPage, setPerPage] = useState(100);
//     const [sortBy, setSortBy] = useState(defaultSortBy);
//     const [sort, setSort] = useState(defaultSortOrder);
//     const [data, setData] = useState([]);
//     const [filteredData, setFilteredData] = useState([]);
//     const [editRow, setEditRow] = useState({});
//     const [assignLoginId, setAssignLoginId] = useState("");
//     const [assignUsername, setAssignUsername] = useState("");
//     let _filteredData = [];

//     // effect gets called after every render
//     // empty array as second argument for the Effect Hook which makes sure to trigger the effect only on component load (mount) and component unload (unmount).
//     useEffect(() => {
//         getUsers();
//         setTitle("Video Portal - User Management");
//         return () => console.log("...unmounting");  //componentWillUnmount.. react will call prior to unmounting
//     }, []);   //array will mean the affect only runs if the value has changed

//     useEffect(() => {
//         setFilteredData(filterData(searchTerm));
//     }, [data, searchTerm]);

//     const getUsers = () => {
//         setLoading(true);
//         fetch(`apiUser/GetAllUsers`).then(r => {
//             //ValidateSession(r);
//             return r.json();
//         }).then((u) => {
//             setData(u);
//             setLoading(false);
//         }).catch(e => {
//             setLoading(false);
//         });
//     }

//     const addUser = () => {
//         const u = { active: true, firstname: "", lastname: "", fullname: "", id: "", loginId: "" };

//         const n = data.filter(x => x.id !== "");
//         n.unshift(u);

//         setData(n);
//         setEditRow(u);
//     }

//     const editMode = (u) => {
//         setEditRow(u);
//     }

//     const editModeCancel = () => {
//         setEditRow({});
//         setData(data.filter(y => y.id !== ""));
//     }

//     const update = () => {
//         setLoading(true);
//         fetch(`/apiUser/updateUser`, {
//             headers: { "Content-Type": "application/json" },
//             method: "POST",
//             body: JSON.stringify(editRow)
//         }).then(r => {
//             //ValidateSession(r);
//             return r.json();
//         }).then(x => {
//             setData(x);
//             setLoading(false);
//             setEditRow({});
//         }).catch(e => {
//             setLoading(false);
//         });
//     }

//     const handleAssignClose = () => {
//         setAssignLoginId("");
//         setAssignUsername("");
//     }

//     const handleAssignOpen = (n) => {
//         setAssignLoginId(n.loginId);
//         setAssignUsername(n.fullname);
//     }

//     const changeSort = (p) => {
//         let sort = "asc";
//         if (sortBy === p && sort === "asc") {
//             sort = "desc";
//         }

//         setSort(sort);
//         setSortBy(p);
//     }

//     const filterData = (f) => {
//         if (f && f !== "") {
//             f = f.toLowerCase();

//             if (data) {
//                 const d1 = data.filter((d) => {
//                     if (d.loginId && d.loginId.toLowerCase().indexOf(f) !== -1 ||
//                         d.fullname && d.fullname.toLowerCase().indexOf(f) !== -1) { return true; }

//                     return false;
//                 });
//                 return d1;
//             }
//             return [];
//         }
//         else {
//             return data ? data : [];
//         }
//     }

//     const stableSort = (array, cmp) => {
//         const stabilizedThis = array.map((el, index) => [el, index]);
//         stabilizedThis.sort((a, b) => {
//             const order = cmp(a[0], b[0]);
//             if (order !== 0) {
//                 return order;
//             }
//             return a[1] - b[1];
//         });
//         return stabilizedThis.map(el => el[0]);
//     }

//     const getSorting = (order, orderBy) => {
//         return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
//     }

//     const desc = (a, b, orderBy) => {
//         if (b[orderBy] < a[orderBy]) {
//             return -1;
//         }
//         if (b[orderBy] > a[orderBy]) {
//             return 1;
//         }
//         return 0;
//     }

//     return (
//         <div>
//             <div style={{ margin: "auto", width: "50%" }}>
//                 <TextField style={{ width: "100%" }} label="Enter Search Term" margin="normal" onKeyUp={e => setSearchTerm(e.target.value)} />
//             </div>
//             <Paper style={{ width: "100%", marginTop: "30px" }}>
//                 {loading && <LinearProgress />}
//                 <br />
//                 <Button color="primary" onClick={() => addUser()}>ADD USER</Button>
//                 <div style={{ overflowX: "auto" }}>
//                     <Table style={{ minWidth: "900px" }} aria-labelledby="tableTitle">
//                         <TableHeaderRow
//                             order={sort}
//                             orderBy={sortBy}
//                             onRequestSort={p => changeSort(p)}
//                             rowCount={filteredData.length}
//                             columns={cols} />
//                         <TableBody>
//                             {stableSort(filteredData, getSorting(sort, sortBy))
//                                 .slice(page * perPage, page * perPage + perPage)
//                                 .map(n => {
//                                     return (
//                                         <TableRow
//                                             hover
//                                             key={n.id}>
//                                             <TableCell align="left" style={{ width: "15%" }}>
//                                                 {
//                                                     editRow && editRow.id === n.id ? <Checkbox checked={editRow.active} onChange={(e) => editMode({ ...editRow, active: !editRow.active })} /> : n.active && <CheckIcon />
//                                                 }
//                                             </TableCell>
//                                             <TableCell align="left" style={{ width: "30%" }}>
//                                                 {
//                                                     editRow && editRow.id === n.id && n.id === "" ? <div><TextField style={{ width: "100%" }} type="text" defaultValue={n.loginId} onChange={(e) => editMode({ ...editRow, loginId: e.target.value })} /><span>example: e123456@bswhealth.org</span></div> : <span>{n.loginId}</span>
//                                                 }
//                                             </TableCell>
//                                             <TableCell align="left" style={{ width: "30%" }}>
//                                                 <span>{n.fullname}</span>
//                                             </TableCell>
//                                             <TableCell align="left" style={{ width: "10%" }}>
//                                                 {
//                                                     editRow && editRow.id === n.id ? <Checkbox checked={editRow.admin} onChange={(e) => editMode({ ...editRow, admin: !editRow.admin })} /> : n.admin && <CheckIcon />
//                                                 }
//                                             </TableCell>
//                                             <TableCell align="left" style={{ width: "15%" }}>
//                                                 {
//                                                     editRow && editRow.id === n.id ?
//                                                         <div>
//                                                             <IconButton onClick={() => update()} color="inherit">
//                                                                 <SaveIcon />
//                                                             </IconButton>
//                                                             <IconButton onClick={() => editModeCancel()} color="inherit">
//                                                                 <CancelIcon />
//                                                             </IconButton>
//                                                         </div> :
//                                                         <div>
//                                                             <IconButton onClick={() => editMode(n)} color="inherit">
//                                                                 <EditIcon />
//                                                             </IconButton>
//                                                             {
//                                                                 !n.admin &&
//                                                                 <IconButton onClick={() => handleAssignOpen(n)} color="inherit">
//                                                                     <SettingsIcon />
//                                                                 </IconButton>
//                                                             }
//                                                         </div>
//                                                 }

//                                             </TableCell>
//                                         </TableRow>
//                                     );
//                                 })}
//                         </TableBody>
//                     </Table>
//                 </div>
//                 <TablePagination
//                     rowsPerPageOptions={[50, 100, 150]}
//                     component="div"
//                     count={filteredData.length}
//                     rowsPerPage={perPage}
//                     page={page}
//                     backIconButtonProps={{
//                         "aria-label": "Previous Page",
//                     }}
//                     nextIconButtonProps={{
//                         "aria-label": "Next Page",
//                     }}
//                     onChangePage={(e, p) => setPage(p)}
//                     onChangeRowsPerPage={(e) => setPerPage(e.target.value)} />
//                 {loading && <LinearProgress />}
//                 <DialogAreaAdmin open={assignLoginId ? true : false} onClose={handleAssignClose} name={assignUsername} loginId={assignLoginId} />
//             </Paper>
//         </div>
//     );
// }

// export const cols = [
//     { id: "active", numeric: false, disablePadding: false, label: "Active" },
//     { id: "loginId", numeric: false, disablePadding: false, label: "Login Id" },
//     { id: "fullname", numeric: false, disablePadding: false, label: "Name" },
//     { id: "admin", numeric: false, disablePadding: false, label: "Portal Admin" },
//     { id: "action", numeric: false, disablePadding: false, label: "" },
// ];

// const user = {
//     active: true,
//     admin: true,
//     firstname: "",
//     fullname: "",
//     id: "",
//     lastname: "",
//     loginId: "",
// };









const TableHeaderRow = ({ order, orderBy, onRequestSort, columns }) => {

    const createSortHandler = (property) => (event) => {
        onRequestSort(property);
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(
                    column => (
                        <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === column.id ? order : false}>
                            <Tooltip
                                title="Sort"
                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}>
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={createSortHandler(column.id)}>
                                    {column.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    );
};

// export default TableHeaderRow;