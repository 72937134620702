import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Loans } from './loan/loans'
import { Loan } from './loan/loan'
import { Settings } from './setting/settings';
import { Layout } from './layout';

export default App => (
  <Router>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/loan' element={<Loans />} />
        <Route path='/loan/:id' element={<Loan />} />
        <Route path='/setting' element={<Settings abcd='hi' />} />
        <Route path='*' element={<Loans />} />
      </Route>
    </Routes>
  </Router>
);