import { GridClientSide, SortOrder } from '../components/gridClientSide';

export const Loans = () => {
    console.log('loans');
    return (
        <>
            <div>Loans</div>
            <GridClientSide defaultSortBy={'title'} defaultSortOrder={SortOrder.asc} />
        </>
    );
};