import { Link, Outlet } from "react-router-dom";

export const Layout = () => {

    return (
        <>
            <nav>
                <Link to='/'>Home </Link>
                <Link to='/loan'>Loans</Link>
                <Link to='/setting'>Settings</Link>
            </nav>
            <Outlet />
            <div>footer</div>
        </>
    );
};